export const validators = {
  isAtleast: min => {
    return v => {
      const num = Number(v || "");
      return num > min || `Surely you must have more than ${min}.`;
    };
  },
  isAtmost: max => {
    return v => {
      const num = Number(v || "");
      return num < max || `Slow down there, cowboy. Too big!`;
    };
  },
  isInteger: v => {
    const pattern = /^-?[0-9]+$/;
    return pattern.test(v) || "How would that even work?";
  },
  isNumber: v => {
    return !isNaN(parseFloat(v || "")) || "How would that even work?";
  }
};
