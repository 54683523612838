<template>
  <v-app>
    <v-main>
      <v-sheet class="header" color="teal lighten-4">
        <v-container class="py-16">
          <v-row justify="center">
            <v-col cols="11" md="12" xl="8">
              <v-row
                align-content="center"
                class="mx-n4"
                justify="space-between"
              >
                <v-col cols="12" md="6" xl="4">
                  <p class="title-text ma-0">AMT Calculator</p>
                  <p
                    :class="[
                      'heading-text',
                      'ma-0',
                      $vuetify.breakpoint.xs
                        ? 'heading-text-xs'
                        : 'heading-text-sm'
                    ]"
                  >
                    Calculate your alternative minimum tax burden
                  </p>
                </v-col>
                <v-col cols="4" class="d-none d-md-flex">
                  <v-row class="ma-0" justify="end" align-content="center">
                    <div class="rounded-circle logo" width="200px">
                      <v-img
                        max-height="220"
                        max-width="220"
                        src="@/assets/calculator.svg"
                        transition="scale-transition"
                      ></v-img>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>

      <AmtCalculator />
    </v-main>
  </v-app>
</template>

<script>
import AmtCalculator from "@/components/AmtCalculator";

export default {
  name: "App",

  components: {
    AmtCalculator
  },

  data: () => ({})
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.header {
  align-items: center;
  display: flex;
}

.heading-text {
  font-family: "Source Serif Pro", "Times New Roman", serif;
  font-weight: 700;
  font-size: 54px;
  line-height: 68px;
  color: #2a3749;
}

.heading-text-sm {
  font-size: 54px;
}

.heading-text-xs {
  font-size: 48px;
}

.logo {
  border-radius: 50%;
  width: 240px;
  height: 240px;

  background: map-get($teal, lighten-5);
  align-items: center;
  justify-content: center;
  display: flex;
}

.title-text {
  font-family: "Nunito", Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #2a3749;
}

.v-application {
  font-family: "Nunito", Arial, sans-serif;
  .title {
    font-family: "Nunito", Arial, sans-serif;
  }
}
</style>
