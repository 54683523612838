<template>
  <div :class="[$vuetify.breakpoint.xs ? 'table-wrapper' : '']">
    <div
      :class="[$vuetify.breakpoint.xs ? 'table-scroll' : '']"
      :style="$vuetify.breakpoint.xs ? { 'min-width': minWidth } : {}"
    >
      <v-row justify="center">
        <v-col cols="12" class="pa-6">
          <v-row justify="start" justify-sm="center">
            <p>
              {{ title }}
            </p>
          </v-row>

          <v-row class="teal lighten-3">
            <v-col v-for="header in headers" v-bind:key="header">
              {{ header }}
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in items" v-bind:key="index">
            <v-col
              v-for="(col, colIndex) in item"
              v-bind:key="colIndex + col + index"
              :class="{ 'teal lighten-5': index % 2 == 1 }"
              >{{ col }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Table",

  props: {
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    minWidth: {
      type: String,
      default: "0px"
    },
    title: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
</style>
