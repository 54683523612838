<template>
  <v-sheet>
    <v-container class="pt-10 mb-2">
      <v-row justify="center">
        <v-col cols="11" md="12" xl="8">
          <v-form v-model="grantForm">
            <v-row class="mt-6 mb-8" justify="space-between">
              <v-col cols="12" sm="6">
                <h2 class="form-group-title">Grant Information</h2>
              </v-col>
              <v-col class="pt-0 pt-sm-3">
                <v-row justify="start" justify-sm="end" class="pr-2 mt-0">
                  <v-btn
                    :disabled="!lessThanMaxGrants"
                    @click="addGrant()"
                    color="teal darken-3"
                    plain
                  >
                    Add another
                    <v-icon right>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <!-- TODO: improve the transitions to not jump -->
            <v-slide-x-reverse-transition group leave-absolute>
              <v-row
                v-bind:key="grant.id"
                v-for="(grant, index) in grants"
                justify="space-between"
                :v-show="true"
              >
                <v-col cols="1" class="pt-5" order="first">
                  <div class="circled-number">{{ index + 1 }}</div>
                </v-col>
                <v-col cols="12" sm="3" order="2">
                  <v-text-field
                    v-model="grant.amount"
                    :hint="optionsHint(index)"
                    :max="max"
                    :min="0"
                    :rules="[
                      validators.isInteger,
                      validators.isAtleast(0),
                      validators.isAtmost(max)
                    ]"
                    step="1000"
                    @change="fetchResults()"
                    label="Number of options"
                    type="number"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="3" order="2">
                  <v-text-field
                    v-model="grant.strikePrice"
                    :max="1000000"
                    :min="0"
                    :step="0.01"
                    :rules="[
                      validators.isNumber,
                      validators.isAtleast(0),
                      validators.isAtmost(1000000)
                    ]"
                    @change="fetchResults()"
                    label="Strike price"
                    prefix="$"
                    type="number"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="3" order="2">
                  <v-text-field
                    v-model="grant.fmvPrice"
                    :max="1000000"
                    :min="0"
                    :rules="[
                      validators.isNumber,
                      validators.isAtleast(0),
                      validators.isAtmost(1000000)
                    ]"
                    :step="0.01"
                    @change="fetchResults()"
                    label="Fair market value"
                    prefix="$"
                    type="number"
                    outlined
                  />
                </v-col>
                <v-col class="pt-5" cols="1" order="0" order-sm="last">
                  <v-row class="ma-0" justify="end">
                    <v-btn
                      :disabled="!greaterThanMinGrants"
                      @click="deleteGrant(index)"
                      depressed
                      icon
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon></v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-slide-x-reverse-transition>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-sheet class="mt-10 mb-6 pt-2 pb-8" color="teal lighten-5">
      <v-container>
        <v-row justify="center">
          <v-col cols="11" md="12" xl="8">
            <v-form v-model="taxForm">
              <h2 class="form-group-title my-8">Tax Information</h2>
              <v-row justify="space-between">
                <v-col cols="12" sm="4" md="3">
                  <v-select
                    v-model="state"
                    :items="states"
                    :menu-props="{ offsetY: true, maxHeight: 400 }"
                    @change="fetchResults()"
                    label="State"
                    clearable
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4" md="3" justify="center">
                  <v-select
                    v-model="filingStatus"
                    :items="filingStatuses"
                    :menu-props="{ offsetY: true }"
                    @change="fetchResults()"
                    label="Filing status"
                    outlined
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field
                    v-model="income"
                    :hint="incomeHint"
                    :max="max"
                    :min="0"
                    :rules="[
                      validators.isNumber,
                      validators.isAtleast(0),
                      validators.isAtmost(max)
                    ]"
                    @change="fetchResults()"
                    label="Yearly income"
                    prefix="$"
                    type="number"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-container>
      <v-row justify="center">
        <v-col>
          <h1
            v-if="error != null"
            :class="[
              'big-title',
              'mt-16',
              'text-center',
              $vuetify.breakpoint.xs ? 'big-text-xs' : 'big-text-sm'
            ]"
          >
            Something went wrong. Please try again.
          </h1>
          <transition mode="out-in" name="fade-in">
            <div :key="amountOwed">
              <h1
                :class="[
                  'big-title',
                  'mt-16',
                  'text-center',
                  $vuetify.breakpoint.xs ? 'big-text-xs' : 'big-text-sm'
                ]"
              >
                You will pay an additional
                <span class="amount-owed"
                  >${{ amountOwed.toLocaleString() }}</span
                >.
              </h1>
              <h3 class="section-title text-center">
                Your breakeven point is
                <span class="breakeven-amount">
                  {{ breakeven.toLocaleString() }}
                </span>
                options.
              </h3>
            </div>
          </transition>
        </v-col>
      </v-row>
      <v-row justify="center">
        <highcharts class="pie mt-16" :options="pieOptions" ref="pie" />
      </v-row>
      <v-sheet class="my-16" color="teal lighten-4" height="4px" />
      <v-row justify="center">
        <v-col cols="11" sm="10" md="8" xl="6">
          <div class="information">
            <h2
              :class="[
                'big-title',
                'mb-16',
                $vuetify.breakpoint.xs ? 'big-text-xs' : 'big-text-sm'
              ]"
            >
              Alternative Minimum Tax
            </h2>
            <p class="section-title">
              What is it?
            </p>
            <p class="text">
              <span class="font-weight-bold"
                >Alternative minimum tax (AMT)</span
              >
              was implemented in 1969 as a parallel tax system to the current
              federal tax system. It was designed to tax many high-income
              households that managed to find loopholes and reduce their tax
              burdens to near zero. <br />
              <br />The basic principle is simple: you must calculate your taxes
              owed under both the federal and AMT systems and pay the
              <span class="font-italic">larger</span> of the two.
            </p>
            <p class="section-title">Who is affected?</p>
            <p class="text">
              Typically, AMT only affects high-income households who have
              alternative sources of income that are not taxed under the federal
              system or are able to reduce their tax liabality dramatically
              through loopholes.<br /><br />
              The one of the most common sources of alternative income are
              <span class="font-weight-bold">
                Incentivized Stock Options (ISOs)</span
              >.
            </p>
            <p class="section-title">What are ISOs?</p>
            <p class="text">
              ISOs are a typical form of compensation for an employee of a
              privately-held company or startup. They are an option to or the
              right to buy shares of the company at a set price called the
              <span class="font-weight-bold">strike price</span>.<br /><br />
              The strike price is the current fair market value, also called the
              <span class="font-weight-bold">409a valuation</span>, of the
              company. In a successful company, this value will of course
              increase over time, so the later you join, the higher it is.<br /><br />
              Options are granted on a
              <span class="font-weight-bold">vesting schedule</span>. To vest
              means to receive your options. Typically, the schedule will be
              over 4 years with a 1 year cliff, however 3 and 5 years are also
              quite common. <br /><br />
              This just means that your options will vest over the course of 4
              years. At the 1 year cliff mark, you will vest 25% of your options
              (or why else would you stay?) and afterwards you will keep vesting
              1/48 of your grant monthly.
            </p>
            <p class="section-title">Where does AMT come in?</p>
            <p class="text mb-0">
              When you do decide to buy some of those shares, it's called
              <span class="font-weight-bold">exercising your options</span>. And
              <span class="font-italic">that</span> is where AMT comes in.
              <br /><br />Let's say your ISO grant was 10,000 options at $10 a
              share. Let's also say that over the course of a year, your
              company's fair market value (409a valuation) has increased to $20
              a share. <br /><br />If you choose to exercise your options, you
              will technically make a profit of $100,000,
              <span class="font-italic">even</span> if you choose not to sell
              the shares immediately.
            </p>
            <p class="section-title text-center mt-8 mb-0">
              exercise profit = (409a - strike) x options
            </p>
            <p class="equation text-center mb-8">
              $100,000 = ($20 - $10) x 10,000
            </p>
            <p class="text">
              Under the federal system, this is
              <span class="font-italic">not</span>
              seen as income, but under AMT it is. You will owe Uncle Sam a
              slice of that sweet $100,000 pie.
            </p>
            <p class="section-title">How is it calculated?</p>
            <p class="text">
              AMT follows some different rules and deductions, which can get a
              little complicated, however, the basic calculation starts with
              your
              <span class="font-weight-bold">AMT taxable income (AMTI)</span>,
              which is a combination of your income, exercised profit, and an
              AMT deduction. <br /><br />
              An <span class="font-weight-bold">AMT deduction</span> is very
              analogous to the federal tax standard deduction, which allows you
              to reduce your taxable income by a set amount. It begins to become
              phased out by 25 cents for every $1 over the threshold.
            </p>

            <Table
              class="mb-16"
              minWidth="620px"
              :headers="[
                'Filing Status',
                'AMT Deduction',
                'Phaseout Threshold'
              ]"
              :items="amtDeductions"
              :title="'AMT Deductions for ' + year"
            />

            <p class="text mb-0">
              Using the previous example, let's also assume you are single
              (&#x1F622;) and make $150,000 a year. Your AMTI will come out to
              $177,100.
            </p>

            <p class="section-title text-center mt-8 mb-0">
              AMTI = income + exercise profit - AMT deduction
            </p>
            <p class="equation text-center mb-8">
              $177,100 = $150,000 + $100,000 - $72,900
            </p>

            <p class="text">
              Your AMT is then taxed based on the tax bracket that you fall
              into.
            </p>

            <Table
              class="mb-16"
              minWidth="640px"
              :headers="[
                'Tax Rate',
                'Single',
                'Married',
                'Separately',
                'Head of Household'
              ]"
              :items="amtBrackets"
              :title="'AMT Brackets for ' + year"
            />

            <p class="text mb-0">
              Seeing as you are single
              <span class="font-italic">and</span> poor, you fall into the 26%
              bracket. See? Things could be worse. This puts your AMT at
              $46,000.
            </p>
            <p class="section-title text-center mt-8 mb-0">
              AMT = AMTI x tax rate
            </p>
            <p class="equation text-center mb-8">
              $46,000 = $177,100 x 26%
            </p>

            <p class="text">
              Based off of your $150,000 income, your federal taxes will be
              roughly $27,000 (trust this number blindly).
              <br /><br />Since your AMT is higher than your federal taxes, you
              will owe $19,000 in addition to your federal taxes. <br /><br />If
              you exercise your options and immediately sell the exercised
              shares, you do <span class="font-italic">not</span> need to worry
              about AMT. <br /><br />Please note, when you sell, you will also
              owe taxes on the difference between the sell price and the 409a
              value at the time of exercise. The profit will be taxed either as
              short term or long term capital gains, depending on if you held
              your shares after exercising for more than a year before selling.
            </p>
            <p class="section-title">
              Is there anyway it can be avoided?
            </p>
            <p class="text">
              Exercising options can become expensive and even prohibitively so.
              <br /><br />This phenomenon happens to many startup employees and
              is referred to as
              <span class="font-weight-bold">golden handcuffs</span>; they are
              unable to afford the high upfront costs of exercising, which
              prevents them from leaving. <br /><br />Fortunately, there
              <span class="font-italic">is</span> something you can do about it.
              <br /><br />The easiest way to drastically reduce your tax burden
              is to <span class="font-weight-bold">early exercise</span>, in
              other words, exercising your options before they have vested.
              Instead of vesting options, you will instead vest your shares.
            </p>
            <p class="section-title">How does it help?</p>
            <p class="text mb-0">
              Recall that your strike price is equal to the value of the company
              when you joined and that your company's value is (hopefully)
              gradually growing over time.<br /><br />
              The sooner you exercise your options, the smaller the difference
              between your strike price and the value of the company will be,
              which reduces your exercise profit significantly, which in turn
              reduces your AMTI significantly.<br /><br />
              In the previous example, if you had early exercised all of your
              options upfront when you first joined your company, your profit
              would be $0.
            </p>
            <p class="equation text-center my-8">
              exercise profit = $0 = ($10 - $10) x 10,000 options
            </p>
            <p class="text mb-0">
              As a consequence your AMTI would be only $77,100, so you would owe
              federal taxes that year. You just saved yourself $19,000!
            </p>
            <p class="equation text-center my-8">
              AMTI = $77,100 = $150,000 + $0 - $72,900
            </p>
            <p class="text">
              Please exercise caution because with this strategy comes a lot of
              inherent risk. If your company never goes public or gets acquired,
              then you will be unable to sell your shares and you will lose all
              of your upfront investment. <br /><br />Therefore, you should
              <span class="font-italic">only</span>
              exercise your options if you believe in your company's future and
              <span class="font-italic">only</span> an amount you feel
              comfortable losing.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-sheet class="grey lighten-4">
      <v-container>
        <v-row justify="center" class="mt-1 pt-16">
          <v-col cols="11" sm="10" md="8" xl="6">
            <p class="disclaimer mb-16">
              Please note, this is just an estimate of the AMT based on the
              given parameters, it is not a fully comprehensive assessment. It
              does not provide legal, financial, or tax advice.
              <br />
              <br />
              All numbers, graphs, charts, and illustrations are for educational
              and illustrative purposes only and are not necessarily indicative
              of your actual tax situation. They are based on the best available
              information at any given time but subject to change without
              notice.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-sheet>
</template>

<script>
import Table from "@/components/Table.vue";

import amtBrackets from "@/data/FederalAmtBrackets.json";
import { calculateAmt, minimizeAmt } from "@/helpers/AmtCalculator.js";
import { calculateTax } from "@/helpers/TaxCalculator.js";
import { validators } from "@/helpers/validators.js";

const minGrants = 1;
const maxGrants = 5;

export default {
  name: "AmtCalculator",

  components: {
    Table
  },

  created() {
    this.fetchResults();
  },

  computed: {
    amountOwed() {
      return this.federalTaxes != null && this.amt != null
        ? Math.round(Math.max(this.amt - this.federalTaxes, 0))
        : 0;
    },
    amtBrackets() {
      const rates = {};
      for (const status in amtBrackets[this.year]) {
        for (const rate in amtBrackets[this.year][status]["rates"]) {
          if (!(rate in rates)) rates[rate] = [`${rate}%`];
          rates[rate].push(
            "$" + amtBrackets[this.year][status]["rates"][rate].toLocaleString()
          );
        }
      }

      const brackets = [];
      for (const rate in rates) {
        brackets.push(rates[rate]);
      }
      for (let i = 0; i < brackets[0].length; i++) {
        if (brackets[0][i] === "$0") {
          brackets[0][i] = "below " + brackets[1][i];
        }
      }

      const lastBracket = brackets[brackets.length - 1];
      for (let i = 1; i < lastBracket.length; i++) {
        lastBracket[i] = "above " + lastBracket[i];
      }

      return brackets;
    },
    amtDeductions() {
      const deductions = [];
      for (const status of this.filingStatuses) {
        const taxInfo = amtBrackets[this.year][status.value];
        deductions.push([
          status.text,
          `$${taxInfo.deduction.toLocaleString()}`,
          `$${taxInfo.phaseout_threshold.toLocaleString()}`
        ]);
      }
      return deductions;
    },
    canFetchResults() {
      return (
        this.state != null &&
        this.filingStatus != null &&
        this.income != null &&
        this.submittedGrants.length > 0 &&
        this.grantForm &&
        this.taxForm
      );
    },
    federalTaxes() {
      return this.taxes != null ? this.taxes.annual.federal.amount : null;
    },
    greaterThanMinGrants() {
      return this.grants.length > minGrants;
    },
    incomeHint() {
      if (this.income == "") {
        return "";
      }

      if (Number(this.income) > 1000000) {
        return "You might want to hire a tax advisor instead!";
      } else if (Number(this.income) >= 500000) {
        return "Oh wowie wow!";
      } else {
        return "";
      }
    },
    lessThanMaxGrants() {
      return this.grants.length < maxGrants;
    },
    max() {
      return 1000000000;
    },
    stateTaxes() {
      return this.taxes != null ? this.taxes.annual.state.amount : null;
    },
    submittedGrants() {
      if (this.grants == null) {
        return null;
      }

      const toSubmit = this.grants.filter(
        grant =>
          grant.amount != "" && grant.strikePrice != "" && grant.fmvPrice != ""
      );

      toSubmit.map(grant => ({
        amount: Number(grant.amount),
        strikePrice: Number(grant.strikePrice),
        fmvPrice: Number(grant.fmvPrice)
      }));

      return toSubmit;
    },
    taxInformation() {
      return {
        filing_status: this.filingStatus,
        pay_rate: Number(this.income),
        state: this.state,
        year: this.year
      };
    },
    year() {
      return 2020;
    }
  },

  data: () => ({
    amt: null,
    breakeven: 0,
    error: null,
    filingStatus: "single",
    filingStatuses: [
      { text: "Single", value: "single" },
      { text: "Married Filing Jointly", value: "married" },
      { text: "Married Filing Separately", value: "married_separately" },
      { text: "Head of Household", value: "head_of_household" }
    ],
    grantForm: true,
    grantId: 1,
    grants: [{ id: 0, amount: 10000, strikePrice: "1.00", fmvPrice: "5.00" }],
    income: 125000,
    pieOptions: {
      chart: {
        type: "pie"
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          colors: ["#00897B", "#4DB6AC", "#B2DFDB"],
          cursor: "pointer"
        }
      },
      series: [
        {
          name: "Taxes",
          data: [
            { name: "Federal", y: 21000 },
            { name: "State", y: 10000 },
            { name: "AMT", sliced: true, y: 2300 }
          ]
        }
      ],
      title: {
        text: "Taxes owed in 2020",
        style: {
          "font-family": "Nunito, Arial, sans-serif"
        }
      },
      tooltip: {
        pointFormat: "{series.name}: <b>${point.y:,.0f}</b>"
      }
    },
    state: "CA",
    states: [
      { text: "Alabama", value: "AL" },
      { text: "Alaska", value: "AK" },
      { text: "Arizona", value: "AZ" },
      { text: "Arkansas", value: "AR" },
      { text: "California", value: "CA" },
      { text: "Colorado", value: "CO" },
      { text: "Connecticut", value: "CT" },
      { text: "Delaware", value: "DE" },
      { text: "Florida", value: "FL" },
      { text: "Georgia", value: "GA" },
      { text: "Hawaii", value: "HI" },
      { text: "Idaho", value: "ID" },
      { text: "Illinois", value: "IL" },
      { text: "Indiana", value: "IN" },
      { text: "Iowa", value: "IA" },
      { text: "Kansas", value: "KS" },
      { text: "Kentucky", value: "KY" },
      { text: "Louisiana", value: "LA" },
      { text: "Maine", value: "ME" },
      { text: "Maryland", value: "MD" },
      { text: "Massachusetts", value: "MA" },
      { text: "Michigan", value: "MI" },
      { text: "Minnesota", value: "MN" },
      { text: "Mississippi", value: "MS" },
      { text: "Missouri", value: "MO" },
      { text: "Montana", value: "MT" },
      { text: "Nebraska", value: "NE" },
      { text: "Nevada", value: "NV" },
      { text: "New Hampshire", value: "NH" },
      { text: "New Jersey", value: "NJ" },
      { text: "New Mexico", value: "NM" },
      { text: "New York", value: "NY" },
      { text: "North Carolina", value: "NC" },
      { text: "North Dakota", value: "ND" },
      { text: "Ohio", value: "OH" },
      { text: "Oklahoma", value: "OK" },
      { text: "Oregon", value: "OR" },
      { text: "Pennsylvania", value: "PA" },
      { text: "Rhode Island", value: "RI" },
      { text: "South Carolina", value: "SC" },
      { text: "South Dakota", value: "SD" },
      { text: "Tennessee", value: "TN" },
      { text: "Texas", value: "TX" },
      { text: "Utah", value: "UT" },
      { text: "Vermont", value: "VT" },
      { text: "Virginia", value: "VA" },
      { text: "Washington", value: "WA" },
      { text: "West Virginia", value: "WV" },
      { text: "Wisconsin", value: "WI" },
      { text: "Wyoming", value: "WY" }
    ],
    taxes: null,
    taxForm: true,
    validators: validators
  }),

  methods: {
    addGrant() {
      if (this.lessThanMaxGrants) {
        this.grants.push({
          amount: null,
          strikePrice: null,
          fmvPrice: null,
          id: this.grantId
        });

        this.grantId++;
      }
    },
    deleteGrant(index) {
      if (this.greaterThanMinGrants) {
        const grant = this.grants[index];
        this.grants.splice(index, 1);

        if (
          grant.amount != null &&
          grant.strikePrice != null &&
          grant.fmvPrice != null
        ) {
          this.fetchResults();
        }
      }
    },
    fetchResults() {
      if (this.canFetchResults) {
        calculateTax(this.taxInformation)
          .then(tax => {
            this.error = null;
            this.taxes = tax;
            this.amt = calculateAmt(this.taxInformation, this.submittedGrants);
            this.breakeven = minimizeAmt(
              this.taxInformation,
              this.submittedGrants[0].strikePrice,
              this.submittedGrants[0].fmvPrice,
              Number(this.federalTaxes)
            );

            this.updatePieChart();
          })
          .catch(error => (this.error = error));
      }
    },
    optionsHint(index) {
      const grant = this.grants[index];
      if (grant.amount == "") {
        return "";
      }

      if (Number(grant.amount) > 10000000) {
        return "Welcome Mr. Bezos! Any job openings?";
      } else if (Number(grant.amount) >= 1000000) {
        return "Welcome, Elon Musk!";
      } else {
        return "";
      }
    },
    updatePieChart() {
      this.pieOptions.series[0].data.find(
        data => data.name === "Federal"
      ).y = this.federalTaxes;
      this.pieOptions.series[0].data.find(
        data => data.name === "State"
      ).y = this.stateTaxes;
      this.pieOptions.series[0].data.find(
        data => data.name === "AMT"
      ).y = this.amountOwed;
    }
  }
};
</script>

<style scoped>
.amount-owed {
  color: #ff5252;
  font-size: 60px;
}

.big-title {
  font-family: "Source Serif Pro", "Times New Roman", Times, serif;
  font-size: 52px;
  font-weight: 700;
  color: #2a3749;
}

.big-title-sm {
  font-size: 52px;
}

.big-text-xs {
  font-size: 44px;
}

.breakeven-amount {
  color: #00bfa5;
  font-size: 36px;
}

.circled-number {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 2px;

  background: #fff;
  border: 3px solid #b2dfdb;
  color: #00695c;
  text-align: center;

  font-size: 18px;
  font-weight: bold;
}

.disclaimer {
  font-size: 14px;
  color: #74788c;
}

.equation {
  color: #757575;
  font-size: 24px;
  font-weight: 600;
}

/* fade transition */
.fade-in-enter-active {
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.form-group-title {
  font-size: 24px;
  font-weight: 500;
  color: #2a3749;
}

.section-title {
  font-size: 32px;
  font-weight: 700;
  color: #2a3749;
}

.text {
  font-size: 20px;
  color: #2a3749;
  font-weight: 500;
  margin-bottom: 70px;
}
</style>
