import axios from "axios";

export async function calculateTax(taxInformation) {
  const options = {
    method: "POST",
    url: "https://taxee.io/api/v2/calculate/" + taxInformation.year,
    headers: {
      authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBUElfS0VZX01BTkFHRVIiLCJodHRwOi8vdGF4ZWUuaW8vdXNlcl9pZCI6IjYwMGJhYTUzYWJlYmMzMTM2YWJjYjc2YiIsImh0dHA6Ly90YXhlZS5pby9zY29wZXMiOlsiYXBpIl0sImlhdCI6MTYxMTM3NzIzNX0.t192nlQPhx_l6RkchGghvqgQ7vgHqpn2sHPGRQrK2pU"
    },
    data: taxInformation
  };

  const response = await axios.request(options);
  return response.data;
}
