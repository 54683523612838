import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";

Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  }
});

Vue.use(HighchartsVue);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: h => h(App)
}).$mount("#app");
