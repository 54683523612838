import amtBrackets from "@/data/FederalAmtBrackets.json";

export function calculateAmt(taxInformation, grantsExercised) {
  const taxBracket =
    amtBrackets[taxInformation.year][taxInformation.filing_status];

  // calculate AMT taxable income
  let amtTaxableIncome = taxInformation.pay_rate;
  for (const grant of grantsExercised) {
    amtTaxableIncome += (grant.fmvPrice - grant.strikePrice) * grant.amount;
  }

  // calculate deduction
  let deduction = taxBracket.deduction;
  if (amtTaxableIncome > taxBracket.phaseout_threshold) {
    deduction -= Math.max(
      (amtTaxableIncome - taxBracket.phaseout_threshold) *
        taxBracket.phaseout_rate,
      0
    );
  }

  amtTaxableIncome -= deduction;

  // calcuate AMT
  let taxesOwed = 0;
  const rates = Object.keys(taxBracket.rates)
    .sort()
    .reverse();
  for (const rate of rates) {
    const bracket = taxBracket.rates[rate];
    if (amtTaxableIncome > bracket) {
      taxesOwed += (rate / 100) * (amtTaxableIncome - bracket);
      amtTaxableIncome = bracket;
    }
  }

  return taxesOwed;
}

export function minimizeAmt(taxInformation, strikePrice, fmvPrice, incomeTax) {
  const taxBracket =
    amtBrackets[taxInformation.year][taxInformation.filing_status];

  const maxTax26 = 0.26 * taxBracket.rates[28];
  let amtIncome =
    incomeTax < maxTax26
      ? incomeTax / 0.26
      : taxBracket.rates[28] + (incomeTax - 0.26 * taxBracket.rates[28]) / 0.28;

  if (amtIncome + taxBracket.deduction > taxBracket.phaseout_threshold) {
    amtIncome +=
      taxBracket.deduction +
      taxBracket.phaseout_threshold * taxBracket.phaseout_rate;
    amtIncome /= 1 + taxBracket.phaseout_rate;
  } else {
    amtIncome += taxBracket.deduction;
  }

  const optionsIncome = amtIncome - taxInformation.pay_rate;
  const maxOptions = Math.round(optionsIncome / (fmvPrice - strikePrice));
  return Math.max(maxOptions, 0);
}
